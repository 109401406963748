import React from "react";
import "./style.css";

export const Desktop = () => {
    return (
        <div className="desktop">
            <div className="div">
                <div className="overlap">
                    <img className="logo" alt="Logo" src="img/logo-4.png" />
                    <p className="text-wrapper">KI-gest&uuml;tze Suche und Auswahl + Gro�es Netzwerk + Alles aus einer Hand =</p>
                    <img className="less-than" alt="Less than" src="../img/less-than.png" />
                    <div className="group">
                        <div className="overlap-group">
                            <div className="text-wrapper-2">Beliebte Produkte</div>
                        </div>
                    </div>
                    <img className="more-than" alt="More than" src="../img/more-than-1.png" />
                    <div className="group-2">
                        <div className="overlap-wrapper">
                            <div className="overlap-2">
                                <div className="overlap-group-wrapper">
                                    <div className="overlap-3">
                                        <div className="div-wrapper">
                                            <div className="overlap-group-2">
                                                <p className="ecaibinet-bietet">
                                                    <span className="span">ecAIbinet</span>
                                                    <span className="text-wrapper-3">
                                                        {" "}
                                                        bietet eine beeindruckende Auswahl von Millionen von Produkten an. Unser ausgereifter
                                                        KI-Suchalgorithmus erm&ouml;glicht es Ihnen, schnell und effizient das zu finden, was Sie
                                                        brauchen. Von Steuerungstechnik bis hin zu Industrieleuchten - wir finden fast alles, was
                                                        Sie suchen.
                                                    </span>
                                                </p>
                                                <div className="text-wrapper-4">Suchen + Finden</div>
                                                <div className="ellipse" />
                                            </div>
                                        </div>
                                        <img className="opera-glasses" alt="Opera glasses" src="/img/opera-glasses.png" />
                                    </div>
                                </div>
                                <div className="rectangle" />
                            </div>
                        </div>
                        <div className="group-3">
                            <div className="overlap-4">
                                <div className="group-4">
                                    <div className="overlap-group-3">
                                        <div className="rectangle-2" />
                                        <p className="mit-dem-ecaibinet">
                                            <span className="text-wrapper-3">Mit dem </span>
                                            <span className="span">ecAIbinet</span>
                                            <span className="text-wrapper-3">
                                                {" "}
                                                Verf&uuml;gbarkeitsIndex kann ermittelt werden wie kritisch die Verf&uuml;gbarkeit von Komponenten ist.
                                                Dieser Parameter wird mittels eines KI-Algorithmuses stetig/ mehrmals am Tag anhand des Preises,
                                                der verf&uuml;gbaren St&uuml;ckzahl und der Lieferzeit von Elektrokomponenten errechnet. Bei
                                                Unterschreiten eines kritischen Werts erfolgt eine Benachrichtigung und es kann ein
                                                automatischer Bestellprozess ausgew&auml;hlt werden.
                                            </span>
                                        </p>
                                        <div className="text-wrapper-5">Vergleichen + Beobachten</div>
                                        <div className="group-5" />
                                    </div>
                                </div>
                                <img className="private-wall-mount" alt="Private wall mount" src="/img/private-wall-mount-camera.png" />
                            </div>
                        </div>
                        <div className="group-6">
                            <div className="overlap-5">
                                <div className="group-7">
                                    <div className="overlap-group-3">
                                        <div className="rectangle-2" />
                                        <p className="der-einkaufsprozess">
                                            <span className="text-wrapper-3">Der Einkaufsprozess war noch nie so einfach. Bei </span>
                                            <span className="span">ecAIbinet</span>
                                            <span className="text-wrapper-3">
                                                {" "}
                                                erhalten Sie f&uuml;r einzelne Artikel oder ganze St&uuml;cklisten eine Preisauskunft in Sekunden. Sie
                                                k&ouml;nnen 24/7 Angebote anfordern und bestellen. Damit sparen Sie nicht nur Zeit, sondern auch
                                                Geld.{" "}
                                            </span>
                                            <span className="span">ecAIbinet </span>
                                            <span className="text-wrapper-3">
                                                hilft Ihnen, die besten Angebote zu finden, lieferf&auml;hig zu bleiben sowie bei jedem Kauf Zeit und
                                                Geld zu sparen.{" "}
                                            </span>
                                        </p>
                                        <div className="text-wrapper-6">Bestellen + Sparen</div>
                                        <div className="group-8" />
                                    </div>
                                </div>
                                <img className="money-box" alt="Money box" src="/img/money-box.png" />
                            </div>
                        </div>
                        <div className="group-9">
                            <div className="overlap-group-3">
                                <div className="text-wrapper-7">Unabh&auml;ngig + Kostenlos</div>
                                <div className="group-10">
                                    <div className="overlap-6">
                                        <div className="group-11" />
                                        <div className="group-12">
                                            <div className="overlap-group-4">
                                                <div className="rectangle-3" />
                                                <p className="ecaibinet-ist-ein">
                                                    <span className="span">ecAIbinet</span>
                                                    <span className="text-wrapper-3">
                                                        {" "}
                                                        ist ein unabh&auml;ngiger &amp; kostenloser Informations- und Vermittlungsservice, der echte
                                                        Preisvergleiche anbietet. <br />
                                                    </span>
                                                    <span className="span">ecAIbinet</span>
                                                    <span className="text-wrapper-3">
                                                        {" "}
                                                        bietet den Nutzern kostenlosen Zugang zu hilfreichen Information, damit Sie kluge
                                                        Kaufentscheidungen treffen k&ouml;nnen.{" "}
                                                    </span>
                                                </p>
                                                <img className="free-shipping" alt="Free shipping" src="/img/free-shipping.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame">
                        <div className="overlap-7">
                            <div className="group-13">
                                <div className="overlap-group-5">
                                    <img className="favorite" alt="Favorite" src="/img/favorite.png" />
                                    <div className="einzelner" />
                                    <div className="rectangle-4" />
                                    <div className="text-wrapper-8">1000+ Beobachter</div>
                                    <div className="text-wrapper-9">Siemens CPU 1215C- 6ES7215-1AG40-0XB0</div>
                                    <div className="text-wrapper-10">576,00 �</div>
                                    <div className="text-wrapper-11">273 Stk. verf&uuml;gbar</div>
                                    <div className="text-wrapper-12">24h</div>
                                    <img className="price-tag-euro" alt="Price tag euro" src="/img/price-tag-euro.png" />
                                    <img className="combo-chart" alt="Combo chart" src="/img/combo-chart.png" />
                                    <img className="delivery" alt="Delivery" src="/img/delivery.png" />
                                    <img className="star-half-empty" alt="Star half empty" src="/img/star-half-empty.png" />
                                    <div className="text-wrapper-13">4,8</div>
                                    <img className="siemens" alt="Siemens" src="/img/siemens-s71200-1.png" />
                                </div>
                            </div>
                            <div className="group-14">
                                <div className="overlap-group-5">
                                    <img className="favorite" alt="Favorite" src="/img/favorite.png" />
                                    <div className="einzelner" />
                                    <div className="rectangle-4" />
                                    <div className="text-wrapper-8">1000+ Beobachter</div>
                                    <div className="text-wrapper-9">Finder 4C.02.8.024.0060 Relaisbaustein</div>
                                    <div className="text-wrapper-10">21,99 �</div>
                                    <div className="text-wrapper-11">32 Stk. verf&uuml;gbar</div>
                                    <div className="text-wrapper-12">24h</div>
                                    <img className="price-tag-euro" alt="Price tag euro" src="/img/price-tag-euro.png" />
                                    <img className="combo-chart" alt="Combo chart" src="/img/combo-chart.png" />
                                    <img className="delivery" alt="Delivery" src="/img/delivery.png" />
                                    <img className="star-half-empty" alt="Star half empty" src="/img/star-half-empty.png" />
                                    <div className="text-wrapper-13">4,3</div>
                                </div>
                            </div>
                            <div className="group-15">
                                <div className="overlap-group-5">
                                    <img className="favorite" alt="Favorite" src="/img/favorite.png" />
                                    <div className="einzelner" />
                                    <div className="einzelner" />
                                    <div className="rectangle-4" />
                                    <div className="text-wrapper-8">750+ Beobachter</div>
                                    <div className="text-wrapper-9">PULS DIMENSION QS10.241 Hutschienen-Netzteil</div>
                                    <div className="text-wrapper-10">349,00 �</div>
                                    <div className="text-wrapper-11">861 Stk. verf&uuml;gbar</div>
                                    <div className="text-wrapper-12">24h</div>
                                    <img className="price-tag-euro" alt="Price tag euro" src="/img/price-tag-euro.png" />
                                    <img className="combo-chart" alt="Combo chart" src="/img/combo-chart.png" />
                                    <img className="delivery" alt="Delivery" src="/img/delivery.png" />
                                    <img className="star-half-empty" alt="Star half empty" src="/img/star-half-empty.png" />
                                    <div className="text-wrapper-13">3,9</div>
                                    <img className="puls-dimension" alt="Puls dimension" src="/img/puls-dimension-qs10-241.png" />
                                </div>
                            </div>
                            <div className="group-16">
                                <div className="overlap-group-5">
                                    <img className="favorite" alt="Favorite" src="/img/favorite.png" />
                                    <div className="einzelner" />
                                    <div className="einzelner" />
                                    <div className="rectangle-4" />
                                    <div className="text-wrapper-8">750+ Beobachter</div>
                                    <div className="text-wrapper-9">PULS DIMENSION QS10.241 Hutschienen-Netzteil</div>
                                    <div className="text-wrapper-10">349,00 �</div>
                                    <div className="text-wrapper-11">861 Stk. verf&uuml;gbar</div>
                                    <div className="text-wrapper-12">24h</div>
                                    <img className="price-tag-euro" alt="Price tag euro" src="/img/price-tag-euro.png" />
                                    <img className="combo-chart" alt="Combo chart" src="/img/combo-chart.png" />
                                    <img className="delivery" alt="Delivery" src="/img/delivery.png" />
                                    <img className="star-half-empty" alt="Star half empty" src="/img/star-half-empty.png" />
                                    <div className="text-wrapper-13">3,9</div>
                                    <img className="puls-dimension" alt="Puls dimension" src="/img/puls-dimension-qs10-241.png" />
                                </div>
                            </div>
                            <div className="group-17">
                                <div className="overlap-group-5">
                                    <img className="favorite" alt="Favorite" src="/img/favorite.png" />
                                    <div className="einzelner" />
                                    <div className="einzelner" />
                                    <div className="rectangle-4" />
                                    <div className="text-wrapper-8">750+ Beobachter</div>
                                    <div className="text-wrapper-9">PULS DIMENSION QS10.241 Hutschienen-Netzteil</div>
                                    <div className="text-wrapper-10">349,00 �</div>
                                    <div className="text-wrapper-11">861 Stk. verf&uuml;gbar</div>
                                    <div className="text-wrapper-12">24h</div>
                                    <img className="price-tag-euro" alt="Price tag euro" src="/img/price-tag-euro.png" />
                                    <img className="combo-chart" alt="Combo chart" src="/img/combo-chart.png" />
                                    <img className="delivery" alt="Delivery" src="/img/delivery.png" />
                                    <img className="star-half-empty" alt="Star half empty" src="/img/star-half-empty.png" />
                                    <div className="text-wrapper-13">3,9</div>
                                    <img className="puls-dimension" alt="Puls dimension" src="/img/puls-dimension-qs10-241.png" />
                                </div>
                            </div>
                            <div className="group-18">
                                <div className="overlap-group-5">
                                    <img className="favorite" alt="Favorite" src="/img/favorite.png" />
                                    <div className="einzelner" />
                                    <div className="einzelner" />
                                    <div className="rectangle-4" />
                                    <div className="text-wrapper-8">750+ Beobachter</div>
                                    <div className="text-wrapper-9">PULS DIMENSION QS10.241 Hutschienen-Netzteil</div>
                                    <div className="text-wrapper-10">349,00 �</div>
                                    <div className="text-wrapper-11">861 Stk. verf&uuml;gbar</div>
                                    <div className="text-wrapper-12">24h</div>
                                    <img className="price-tag-euro" alt="Price tag euro" src="/img/price-tag-euro.png" />
                                    <img className="combo-chart" alt="Combo chart" src="/img/combo-chart.png" />
                                    <img className="delivery" alt="Delivery" src="/img/delivery.png" />
                                    <img className="star-half-empty" alt="Star half empty" src="/img/star-half-empty.png" />
                                    <div className="text-wrapper-13">3,9</div>
                                    <img className="puls-dimension" alt="Puls dimension" src="/img/puls-dimension-qs10-241.png" />
                                </div>
                            </div>
                            <div className="group-19">
                                <div className="overlap-group-5">
                                    <img className="favorite" alt="Favorite" src="/img/favorite.png" />
                                    <div className="einzelner" />
                                    <div className="einzelner" />
                                    <div className="rectangle-4" />
                                    <div className="text-wrapper-8">750+ Beobachter</div>
                                    <div className="text-wrapper-9">PULS DIMENSION QS10.241 Hutschienen-Netzteil</div>
                                    <div className="text-wrapper-10">349,00 �</div>
                                    <div className="text-wrapper-11">861 Stk. verf&uuml;gbar</div>
                                    <div className="text-wrapper-12">24h</div>
                                    <img className="price-tag-euro" alt="Price tag euro" src="/img/price-tag-euro.png" />
                                    <img className="combo-chart" alt="Combo chart" src="/img/combo-chart.png" />
                                    <img className="delivery" alt="Delivery" src="/img/delivery.png" />
                                    <img className="star-half-empty" alt="Star half empty" src="/img/star-half-empty.png" />
                                    <div className="text-wrapper-13">3,9</div>
                                    <img className="puls-dimension" alt="Puls dimension" src="/img/puls-dimension-qs10-241.png" />
                                </div>
                            </div>
                            <img className="image" alt="Image" src="/img/image-5.png" />
                        </div>
                    </div>
                    <div className="frame-2">
                        <div className="text-wrapper-14">Website is under Construction</div>
                    </div>
                    <div className="group-20">
                        <img className="img" alt="Logo" src="/img/logo-4.png" />
                        <p className="das-weltweit-einzige">
                            -&nbsp;&nbsp;Das weltweit einzige Vergleichs- und Verf&uuml;gbarkeitsportal f&uuml;r Elektrokomponenten
                        </p>
                    </div>
                </div>
                <img className="logo-2" alt="Logo" src="/img/logo-1.png" />
                <img className="menu" alt="Menu" src="/img/menu.png" />
                <div className="text-wrapper-15">Kategorien</div>
                <div className="navbar">
                    <div className="text-wrapper-16">Referenzen</div>
                    <div className="text-wrapper-17">&uuml;ber uns</div>
                    <div className="text-wrapper-18">Leitfaden</div>
                    <div className="text-wrapper-19">Produkt</div>
                </div>
                <div className="group-21">
                    <div className="overlap-8">
                        <div className="group-22">
                            <div className="overlap-group-6">
                                <div className="text-wrapper-20">Steuerungs-technik</div>
                                <img className="electric-fence" alt="Electric fence" src="/img/electric-fence.png" />
                            </div>
                        </div>
                        <div className="group-23">
                            <div className="overlap-9">
                                <div className="text-wrapper-21">Sicherheits-technik</div>
                                <img className="warning-shield" alt="Warning shield" src="/img/warning-shield.png" />
                            </div>
                        </div>
                        <div className="group-24">
                            <img className="switch" alt="Switch" src="/img/switch.png" />
                            <div className="text-wrapper-22">Schalttechnik</div>
                        </div>
                        <div className="group-25">
                            <div className="overlap-10">
                                <div className="text-wrapper-23">Schutztechnik</div>
                                <img className="safe-in" alt="Safe in" src="/img/safe-in.png" />
                            </div>
                        </div>
                        <div className="group-26">
                            <div className="overlap-11">
                                <div className="text-wrapper-24">Antriebstechnik</div>
                                <img className="engine" alt="Engine" src="/img/engine.png" />
                            </div>
                        </div>
                        <div className="group-27">
                            <div className="overlap-9">
                                <div className="text-wrapper-21">Spannungs-versorgung</div>
                                <img className="plug" alt="Plug" src="/img/plug.png" />
                            </div>
                        </div>
                        <div className="group-28">
                            <div className="text-wrapper-25">Verbindungs-technik</div>
                            <img className="connected" alt="Connected" src="/img/connected.png" />
                        </div>
                        <div className="group-29">
                            <div className="overlap-12">
                                <div className="mess">Mess- &amp; Regelungstechnik</div>
                                <img className="ruler" alt="Ruler" src="/img/ruler.png" />
                            </div>
                        </div>
                        <div className="group-30">
                            <div className="overlap-13">
                                <div className="text-wrapper-26">Industrielle Kommunikation</div>
                                <img className="internet-hub" alt="Internet hub" src="/img/internet-hub.png" />
                            </div>
                        </div>
                        <div className="group-31">
                            <div className="text-wrapper-27">Sensorik-systeme</div>
                            <img className="proximity-sensor" alt="Proximity sensor" src="/img/proximity-sensor.png" />
                        </div>
                        <div className="group-32">
                            <div className="text-wrapper-28">Prozess-instrumentierung</div>
                            <img className="pressure" alt="Pressure" src="/img/pressure.png" />
                        </div>
                        <div className="group-33">
                            <div className="text-wrapper-29">Befehls- &amp; Meldeger&auml;te</div>
                            <img className="stack-light" alt="Stack light" src="/img/stack-light.png" />
                        </div>
                        <div className="group-34">
                            <div className="overlap-14">
                                <div className="text-wrapper-30">Industrie-geh&auml;use</div>
                                <img className="cabinet-light" alt="Cabinet light" src="/img/cabinet-light.png" />
                            </div>
                        </div>
                        <div className="group-35">
                            <div className="text-wrapper-29">Industrie-leuchten</div>
                            <img className="light-on" alt="Light on" src="/img/light-on.png" />
                        </div>
                        <div className="group-36">
                            <div className="overlap-15">
                                <div className="text-wrapper-29">Sonstiges</div>
                                <img className="add-basket" alt="Add basket" src="/img/add-basket.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="group-37">
                    <div className="overlap-16">
                        <p className="KI-sucht-und-findet">
                            KI sucht und findet. <br />
                            KI vergleicht und beobachtet. <br />
                            Bleibe lieferf&auml;hig und spare.
                        </p>
                        <p className="p">
                            Mit cAIbinet kannst du Millionen von Elektrokomponenten bei &uuml;ber 100 Anbietern mit einem revolution�ren
                            KI-Algorithmus auf Verf&uuml;gbarkeit pr&uuml;fen, beobachten sowie deren Preise und Lieferzeiten vergleichen.
                        </p>
                        <img className="electric" alt="Electric" src="/img/electric-4198293-1920-1.png" />
                        <div className="group-38">
                            <div className="overlap-group-7">
                                <div className="text-wrapper-31">Suchbegriff/ Artikel-Nr./ Hersteller...</div>
                                <img className="search" alt="Search" src="/img/search.png" />
                                <img className="forward-button" alt="Forward button" src="/img/forward-button.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame-3">
                    <div className="overlap-17">
                        <img className="vector" alt="Vector" src="/img/vector-1.svg" />
                        <img className="vector-2" alt="Vector" src="/img/vector-2.svg" />
                        <img className="vector-3" alt="Vector" src="/img/vector-5.svg" />
                        <img className="group-39" alt="Group" src="/img/group-66.png" />
                    </div>
                    <div className="group-40">
                        <div className="overlap-group-8">
                            <img className="logo-3" alt="Logo" src="/img/logo-4.png" />
                            <p className="werden-sie-teil-des">
                                Werden Sie Teil
                                des&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                                Netzwerks
                            </p>
                            <p className="text-wrapper-32">
                                Ihr Unternehmen besitzt Expertise im Bereich Online-Vertrieb von Elektrokomponenten oder Produkten rund
                                um den Schaltschrankbau? Dann werden Sie Teil des radikal vereinfachten, digitalen Einkaufsprozesses und
                                erhalten einen &uuml;berregionalen Zugang zu &uuml;ber 10.000 Kunden
                            </p>
                            <div className="rectangle-5" />
                            <div className="text-wrapper-33">Zu den Konditionen</div>
                        </div>
                    </div>
                </div>
                <p className="ob-maschinenbauer">
                    Ob Maschinenbauer, Schalt- und Steuerungsanlagenbauer oder Forschungsinstitute -&nbsp;&nbsp;unser Service
                    &uuml;berzeugt !
                </p>
                <div className="overlap-18">
                    <div className="group-41">
                        <img className="logo-4" alt="Logo" src="/img/logo-4.png" />
                        <div className="text-wrapper-34">Das sagen K&auml;ufer &uuml;ber</div>
                    </div>
                    <img className="image-2" alt="Image" src="/img/image-19.png" />
                </div>
                <img className="more-than-2" alt="More than" src="/img/more-than.png" />
                <img className="less-than-2" alt="Less than" src="/img/less-than.png" />
                <div className="group-42">
                    <div className="overlap-19">
                        <div className="group-43">
                            <div className="overlap-20">
                                <img className="people" alt="People" src="/img/people.png" />
                                <img className="checked-checkbox" alt="Checked checkbox" src="/img/checked-checkbox.png" />
                                <div className="group-44">
                                    <div className="overlap-group-9">
                                        <div className="rectangle-6" />
                                        <div className="x"> x</div>
                                    </div>
                                </div>
                                <div className="group-45">
                                    <div className="overlap-group-9">
                                        <div className="rectangle-6" />
                                        <div className="x"> x</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="div-2">
                            <div className="overlap-19">
                                <img className="time-limit" alt="Time limit" src="/img/time-limit.png" />
                                <div className="div-2">
                                    <div className="overlap-21">
                                        <img className="mobile-order" alt="Mobile order" src="/img/mobile-order.png" />
                                        <div className="group-46">
                                            <div className="overlap-22">
                                                <div className="text-wrapper-35">Anmelden</div>
                                                <div className="div-2">
                                                    <div className="overlap-23">
                                                        <div className="text-text-text">
                                                            Text
                                                            <br />
                                                            Text
                                                            <br />
                                                            Text
                                                        </div>
                                                        <div className="text-text-text-2">
                                                            Text
                                                            <br />
                                                            Text
                                                            <br />
                                                            Text
                                                        </div>
                                                        <div className="text-text-text-3">
                                                            Text
                                                            <br />
                                                            Text
                                                            <br />
                                                            Text
                                                        </div>
                                                        <div className="text-text-text-4">
                                                            Text
                                                            <br />
                                                            Text
                                                            <br />
                                                            Text
                                                        </div>
                                                        <div className="text-text-text-5">
                                                            Text
                                                            <br />
                                                            Text
                                                            <br />
                                                            Text
                                                        </div>
                                                        <div className="text-wrapper-36">Upload</div>
                                                        <div className="text-wrapper-37">Dateneingabe</div>
                                                        <div className="text-wrapper-38">Lieferzeitpunkt w&auml;hlen</div>
                                                        <div className="vergleich-auswahl">Vergleich &amp; Auswahl</div>
                                                        <div className="text-wrapper-39">Einfaches Bestellen</div>
                                                        <div className="text-wrapper-40">Auftragsbest&auml;tigung</div>
                                                        <div className="ellipse-2" />
                                                        <div className="ellipse-3" />
                                                        <div className="group-47">
                                                            <div className="overlap-group-10">
                                                                <img className="handshake" alt="Handshake" src="/img/handshake.png" />
                                                                <div className="div-3" />
                                                            </div>
                                                        </div>
                                                        <div className="ellipse-4" />
                                                        <div className="import-pdf-wrapper">
                                                            <img className="import-pdf" alt="Import pdf" src="/img/import-pdf.png" />
                                                        </div>
                                                        <div className="group-wrapper">
                                                            <div className="group-48" />
                                                        </div>
                                                        <img className="line" alt="Line" src="/img/line-6.svg" />
                                                        <img className="line-2" alt="Line" src="/img/line-7.svg" />
                                                        <img className="line-3" alt="Line" src="/img/line-8.svg" />
                                                        <img className="line-4" alt="Line" src="/img/line-9.svg" />
                                                        <img className="line-5" alt="Line" src="/img/line-10.svg" />
                                                        <img className="line-6" alt="Line" src="/img/line-11.svg" />
                                                        <img className="mind-map" alt="Mind map" src="/img/mind-map.png" />
                                                    </div>
                                                    <div className="text-text-text-6">
                                                        Text
                                                        <br />
                                                        Text
                                                        <br />
                                                        Text
                                                    </div>
                                                    <div className="text-text-text-7">
                                                        Text
                                                        <br />
                                                        Text
                                                        <br />
                                                        Text
                                                    </div>
                                                    <div className="div-3">
                                                        <img
                                                            className="signing-a-document"
                                                            alt="Signing a document"
                                                            src="/img/signing-a-document.png"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
